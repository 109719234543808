import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BackLink from '../components/links/BackLink/BackLink'
import { Article } from '../components/common.styled'
import QRCodeLinks from '../components/QRCodeLinks/QRCodeLinks'

const QRCodeLinksTemplate = ({data: {post}}) => {
  return <Layout>
    <Seo title={post.title} />
    <Article
        className={`page ${post.slug}`}
        itemScope
        itemType='http://schema.org/Article'
      >
        <header>
          <BackLink uri={post.parent?.node?.uri} />
          <h1 itemProp='headline'>{parse(post.title)}</h1>
        </header>
        <section itemProp='articleBody'>
          {post.content && parse(post.content)}
          <QRCodeLinks page='community-prescribers' />
        </section>
      </Article>
  </Layout>
}

export default QRCodeLinksTemplate

export const pageQuery = graphql`
  query CommunityPrescribersQRCodeLinksQuery(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      menuOrder
      date(formatString: "MMMM DD, YYYY")
      template {
        templateName
      }
      parent: wpParent {
        node {
          uri
          slug
          ... on WpPage {
            menuOrder
            wpChildren {
              nodes {
                ... on WpPage {
                  uri
                  title
                  menuOrder
                }
              }
            }
          }
        }
      }
    }
  }
`
